.main{
  background-image: url("./images/roberto-h-qToVxSYXPYU-unsplash.jpg");
  background-repeat: no-repeat;
}

.about .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #5f687b;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #16df7e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/* table th:first-child{
  border-radius:5px 0 0 10px;

}

table th:last-child{
  border-radius:0 10px 10px 0;
  
} */

.BrowserRouter{
  background-color: #5969f3;
}

.footer {
  background: #f6f9ff;
  padding: 0 0 30px 0;
  font-size: 14px;
  clear: both;
    position: relative;
    height: 200px;
    margin-top: -200px;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #012970;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type=submit]:hover {
  background: #5969f3;
}


@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #012970;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #012970;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: #013289;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #4154f1;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #012970;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

#hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #f5f8fd url("./images/intro-bg.jpg") center top no-repeat;
  background-size: cover;
  padding-top: 110px;
}

@media (max-width: 991px) {
  #hero {
    height: auto;
    padding: 100px 0 60px 0;
  }

  #hero .container {
    height: auto !important;
  }

  #hero .intro-img {
    width: 80%;
  }
}

@media (min-width: 992px) {
  #hero .intro-info {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  #hero .intro-info {
    text-align: center;
    padding-top: 40px;
  }
}

#hero .intro-info h2 {
  color: #413e66;
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 700;
}

#hero .intro-info h2 span {
  color: #1bb1dc;
}

@media (max-width: 991px) {
  #hero .intro-info h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }
}

#hero .intro-info .btn-get-started,
#hero .intro-info .btn-services {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #1bb1dc;
  color: #fff;
}

#hero .intro-info .btn-get-started:hover,
#hero .intro-info .btn-services:hover {
  background: #0a98c0;
}

.project-table{
  overflow-y: scroll;
  max-height: 400px;
}



@media only screen and (max-width: 992px) {
  .download-report{
    margin-top: 1%;
    margin-left: -0% !important;
    
  }

  .add-emp{
    margin-top: 1%;
  }
}

.contractor-body{
  background: #f5f8fd url("./images/Untitled\ design\ \(7\).png")  ;
  background-size: cover; /* or "contain" depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
overflow: auto;

}

.modal-size {

  width: 100% !important;
  




}